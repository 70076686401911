<!-- intro section
================================================== -->
<section id="intro">   

<div class="intro-overlay"></div>	

<div class="intro-content">
    <div class="row">

        <div class="col-twelve">

            <h5>...</h5>
            <h1>Hi it's Nicole</h1>

            <p class="intro-position">
                <span>Game Designer</span>
                <span>Blender</span> 
                <span>3Ds Max</span> 
                <span>Substance Painter</span>
                <span>Unity</span>
                <span>Unreal Engine</span>
                <span>Sales</span>
                
            </p>

            <a class="button stroke smoothscroll" href="#about" title="">More About Me</a>

        </div>  
        
    </div>   		 		
</div> <!-- /intro-content --> 

<ul class="intro-social">        
    <li><a href="https://www.facebook.com/"><i class="fa fa-facebook"></i></a></li>
    <li><a href="https://www.linkedin.com/in/nicole-o-brien-267162175/"><i class="fa fa-linkedin"></i></a></li>
    <li><a href="https://www.instagram.com/"><i class="fa fa-instagram"></i></a></li>
    <li><a href="https://docs.google.com/document/d/1IpGqOkGBDXcbJw1NHnWvg3RrYVCgP6h7/edit?usp=sharing&ouid=115436486352878839217&rtpof=true&sd=true"><i class="fa fa-bookmark"></i></a></li>
</ul> <!-- /intro-social -->      	

</section> <!-- /intro -->


<!-- about section
================================================== -->
<section id="about">  

<div class="row section-intro">
    <div class="col-twelve">

        <h5>About</h5>
        <h1>Let me introduce myself.</h1>

        <div class="intro-info">

          <img src="assets/img/profile-pic.jpg" alt="Profile Picture">
          <img src="assets/img/poncho.jpg" alt="Profile Picture">

          <p class="lead">
            Hello, my name is Nicole O'Brien and welcome to my website! I am a game design graduate from TUD with over six years of game design experience.
            I'm currently trying to jump start my career in video game industry.
          </p>
          <!-- <br><br><a class="button stroke smoothscroll" href="#contact" title="">Contact me!</a> -->
        </div>   			

    </div>   		
</div> <!-- /section-intro -->

<div class="row about-content">

    <div class="col-six tab-full">

        <h3>Profile</h3>
        <p class="lead">I mostly make 3D assets, but in my spare time you'll find me with family/friends, playing video games, creative writing, with my cat Poncho or swimming.</p>

        <ul class="info-list">
            <li>
                <strong>Full name:</strong>
                <span>Nicole Jacinta O'Brien</span>
            </li>
            <li>
                <strong>Birth Date:</strong>
                <span>30th April 2000</span>
            </li>
            <li>
                <strong>Graduate:</strong>
                <span>Game Design</span>
            </li>
            <li>
                <strong>Website:</strong>
                <span>nicole-obrien.net</span>
            </li>
            <li>
                <strong>Email:</strong>
                <span>nicoleobrien304@hotmail.co.uk</span>
            </li>

        </ul> <!-- /info-list -->

    </div>

    <div class="col-six tab-full">

        <h3>Skills</h3>
        <p class="lead">Nowadays I mainly make games with Blender, Substance Painter and Unreal Engine. My recent work skills involve sales, communication and leadership. </p>

            <ul class="skill-bars">
                <li>
                    <div class="progress percent90"><span>90%</span></div>
                    <strong>Sales</strong>
                </li>
                <li>
                    <div class="progress percent90"><span>90%</span></div>
                    <strong>Communication</strong>
                </li>
                <li>
                    <div class="progress percent90"><span>90%</span></div>
                    <strong>Time Management</strong>
                </li>
                <li>
                    <div class="progress percent90"><span>90%</span></div>
                    <strong>Unity</strong>
                </li>
                <li>
                    <div class="progress percent80"><span>80%</span></div>
                    <strong>Blender</strong>
                </li>
                <li>
                    <div class="progress percent80"><span>80%</span></div>
                    <strong> Team Building</strong>
                </li>
                <li>
                    <div class="progress percent80"><span>80%</span></div>
                    <strong>Leadership</strong>
                </li>
                <li>
                    <div class="progress percent70"><span>70%</span></div>
                    <strong>Unreal Engine</strong>
                </li>
                <li>
                    <div class="progress percent60"><span>60%</span></div>
                    <strong>3Ds Max</strong>
                </li>
                <li>
                    <div class="progress percent60"><span>60%</span></div>
                    <strong>Substance Painter</strong>
                </li>
                <li>
                    <div class="progress percent30"><span>30%</span></div>
                    <strong>C#</strong>
                </li>
            </ul> <!-- /skill-bars -->		

    </div>

</div>

<div class="row button-section">
    <div class="col-twelve">
        <a href="#contact" title="Hire Me" class="button stroke smoothscroll">Contact Me!</a>
        <a href="https://www.linkedin.com/in/nicole-o-brien-267162175/" title="Download CV" class="button button-primary">LinkedIn</a>
    </div>   		
</div>

</section> <!-- /process-->    


<!-- resume Section
================================================== -->
<section id="resume" class="grey-section">

    <div class="row section-intro">
        <div class="col-twelve">

            <h5>Resume</h5>
            <h1>Accomplishments</h1>

            <p class="lead">Here's a summary of my work experience and education so far.</p>

        </div>   		
    </div> <!-- /section-intro--> 

    <div class="row resume-timeline">

        <div class="col-twelve resume-header">

            <h2>Work Experience</h2>

        </div> <!-- /resume-header -->

        <div class="col-twelve">

          <div class="timeline-wrap">

            <div class="timeline-block">

              <div class="timeline-ico">
                <i class="fa fa-briefcase"></i>
              </div>

              <div class="timeline-header">
                <h3>Retail Sales Assistant</h3>
                <p>August 2019 - December 2022</p>
              </div>

              <div class="timeline-content">
                <h3 class="remove-margin">Eir Grafton Street</h3>
                <p class="lead remove-margin" style="font-size: initial;">Skills: Sales | Customer Service | Communication | Leadership | Trainer | Time Management</p><h4></h4>
                <p class="lead">
                  My role as a Retail Sales Assistant at Eir Grafton Street, encompassed a range of responsibilities. I provided customer<span style="color: black" id="dots">...</span>
                  <span id="more">
                    service, specialising in selling phones, Wi-Fi services, and contracts. Meeting monthly targets was a key part of the job. I excelled in profiling customers to match them with the right products, offering recommendations, and effectively upselling.
                    <br/><br/>
                    In addition to sales, I handled complex customer complaints with a calm and friendly approach, efficiently resolving issues. When my manager was absent, I assumed leadership responsibilities, including shop management, door operations, stocktaking, cash handling, staff training, and addressing escalated customer queries. Training new staff in both sales and software was also within my purview.
                    <br/><br/>
                    My experience in this role developed my customer service and leadership skills, enabling me to handle various aspects of store operations effectively.

                  </span>
                  <br>
                  <a style="color:#1976d2" class="primary-button" onclick="showMore('dots', 'more', 'myBtn')" id="myBtn" #myBtn>read more</a>
                  <br>
                </p>

              </div>

            </div> <!-- /timeline-block -->

            <div class="timeline-block">

              <div class="timeline-ico">
                <i class="fa fa-briefcase"></i>
              </div>

              <div class="timeline-header">
                <h3>Sales Assistant</h3>
                <p>April 2019 - July 2019</p>
              </div>

              <div class="timeline-content">
                <h3 class="remove-margin">BaxterStorey</h3>
                <p class="lead remove-margin" style="font-size: initial;">Skills: Customer Service | Cash Handling | Stocking Shelves | Problem-Solving | Organisation</p><h4></h4>
                <p class="lead animated pulse infinite">
                  Working as a sales assistant was a very fast-paced and interative environment. A normal shift would involve going through stock checking for out of date products, restocking shelves with fresh products,  <span style="color: black" id="dots1" #dots>...</span>
                  <span id="more1" #more>
                    serving customers, cleaning the shop and locking up the shop at the end of the shift. The role was pivotal in creating a welcoming and organised shopping environment, and it often involves building rapport with loyal customers while attracting new ones. The job provided the opportunity to learn about various products, how to interact with customers and ensure the shop is clean and safe for customers.
                    </span>
                  <br>
                  <a style="color:#1976d2" class="primary-button" onclick="showMore('dots1', 'more1', 'myBtn1')" id="myBtn1" #myBtn>read more</a>
                  <br>

                </p>
                <br><br>
                <p>

                </p>
              </div>

            </div> <!-- /timeline-block -->

            <div class="timeline-block">

              <div class="timeline-ico">
                <i class="fa fa-briefcase"></i>
              </div>

              <div class="timeline-header">
                <h3>Event Staff</h3>
                <p>November 2018 - March 2019</p>
              </div>

              <div class="timeline-content">
                <h3 class="remove-margin">Radisson Blu</h3>
                <p class="lead remove-margin" style="font-size: initial;">Skills: Customer Service | Health & Safety | Food & Beverage | Organisation</p><h4></h4>
                <p class="lead animated pulse infinite">
                  Working as an event staff member was organised and fun environment. During a normal shift I would set up the event room with tebles, chair and cutlery, have a meeting with the manager to about the how the event will run,<span style="color: black" id="dots2" #dots>...</span>
                  <span id="more2" #more>
                    welcome guest with drinks and make sure everyone is seated, serve guests with food and beverages. clean the dishes from customers and ensure the have their needs met and clean up the event room when the guests have left. The role demanded excellent communication and problem-solving skills, as well as the ability to adapt quickly to changing situations. I often worked in a collaborative environment and had the chance to be a part of memorable experiences, making it a rewarding experience and worked and served alot of fun and amazing people.
                  </span>
                  <br>
                  <a style="color:#1976d2" class="primary-button" onclick="showMore('dots2', 'more2', 'myBtn2')" id="myBtn2" #myBtn>read more</a>
                  <br>

                </p>
                <br><br>
                <p>

                </p>
              </div>

            </div> <!-- /timeline-block -->

            <div class="timeline-block">

              <div class="timeline-ico">
                <i class="fa fa-briefcase"></i>
              </div>

              <div class="timeline-header">
                <h3>Lounge Staff</h3>
                <p>September 2018 - December 2018</p>
              </div>

              <div class="timeline-content">
                <h3 class="remove-margin">Sheldon Park Hotel</h3>
                <p class="lead remove-margin" style="font-size: initial;">Skills: Customer Service | Food & Beverage | Health & Safety | Cash Handling</p><h4></h4>
                <p class="lead animated pulse infinite">
                  Working as a lounge staff member was a. During a normal shift I would be given float money to be used to give change to customers, take orders for customers, serve customers their beverages and given them their change, <span style="color: black" id="dots3" #dots>...</span>
                  <span id="more3" #more>
                    pick up empty glasses and clean up the bar and lounge when it closes. This role demanded strong interpersonal skills, as building rapport with customers and ensuring their satisfaction was essential. While it was a social and enjoyable profession, it was important to be professional and safe.
                  </span>
                  <br>
                  <a style="color:#1976d2" class="primary-button" onclick="showMore('dots3', 'more3', 'myBtn3')" id="myBtn3" #myBtn>read more</a>
                  <br>

                </p>
                <br><br>
                <p>

                </p>
              </div>

            </div> <!-- /timeline-block -->

          </div> <!-- /timeline-wrap -->   			

        </div> <!-- /col-twelve -->
        
    </div> <!-- /resume-timeline -->

    <div class="row resume-timeline">

        <div class="col-twelve resume-header">

            <h2>Education</h2>

        </div> <!-- /resume-header -->

        <div class="col-twelve">

          <div class="timeline-wrap">

            <div class="timeline-block">

              <div class="timeline-ico">
                <i class="fa fa-graduation-cap"></i>
              </div>

              <div class="timeline-header">
                <h3>Bachelor Degree</h3>
                <p>September 2020 - June 2023</p>
              </div>

              <div class="timeline-content">
                <h3 class="remove-margin">Technological University Dublin</h3><h4></h4>
                <p class="lead remove-margin" style="font-size: initial;">Technology used: Blender | Unreal Engine | Git/Github | Unity | 3Ds Max | Substance Painter | C#</p>
                <p class="lead animated pulse infinite">
                  During my time at Technological University Dublin, I pursued a Bachelor of Arts in Game Design, and it was an incredibly enriching journey. I graduated with a 2nd Class Honours, Lower Division (2:2),<span style="color: black" id="dots5" #dots>...</span>
                  <span id="more5" #more>
                    demonstrating my dedication and passion for the field. My final year was marked by challenging and rewarding modules, including Game Project, Business in Games, Narrative, and a Dissertation, which allowed me to delve deep into game design concepts and theory.
                    <br/><br/>
                    I also had the privilege of serving as the Student Representative for both the 2021-2022 and 2022-2023 academic years, where I actively engaged with my peers and faculty to improve the student experience. A significant highlight of my education was our final year Game Group Project, "Assault The Tower." In this project, I played a pivotal role in developing and managing a First-Person Shooter game using tools like Unreal Engine 5, Blender, and Substance Painter. This endeavor encompassed a wide range of responsibilities, from designing levels and 3D modeling to programming with Blueprints and creating AI functionality. My university experience has not only honed my skills but also instilled in me a profound passion for the world of game design and development.
                   
                  </span>
                  <br>
                  <a style="color:#1976d2" class="primary-button" onclick="showMore('dots5', 'more5', 'myBtn5')" id="myBtn5" #myBtn>read more</a>
                  <br>

                </p>
                <br><br>
                <p>

                </p>
              </div>

            </div> <!-- /timeline-block -->

            <div class="timeline-block">

              <div class="timeline-ico">
                <i class="fa fa-graduation-cap"></i>
              </div>

              <div class="timeline-header">
                <h3>Higher National Diploma</h3>
                <p>September 2018 - April 2020</p>
              </div>

              <div class="timeline-content">
                <h3 class="remove-margin">Ballyfermot College of Further Education</h3><h4></h4>
                <p class="lead remove-margin" style="font-size: initial;">Technology used: 3Ds Max | Unity | Blender | SketchUp | C# | Maya</p>
                <p class="lead animated pulse infinite">
                  My experience at Ballyfermot College of Further Education pursuing a Diploma of Higher Education in HND Creative Media Production with a focus on Computer Game Design was truly transformative. Achieving a Distinction grade reflects my dedication and commitment to this field.<span style="color: black" id="dots6" #dots>...</span>
                  <span id="more6" #more>
                    Achieving a Distinction grade reflects my dedication and commitment to this field.
                    <br/><br/>
                    Throughout the program, I had the privilege of diving deep into various facets of game design, animation, and environment and level design. These modules provided me with invaluable knowledge and practical skills that form the foundation of my creative journey in the gaming industry.
                    <br/><br/>
                    Studying at Ballyfermot College not only equipped me with technical proficiency but also fostered a deep appreciation for the artistry and storytelling potential within the world of computer game design. It was an environment that encouraged innovation and creativity, allowing me to explore and push the boundaries of my imagination.
                    <br/><br/>
                    Overall, my time at Ballyfermot College was instrumental in shaping my passion for game design and prepared me for a dynamic and rewarding career in the field.
                  </span>
                  <br>
                  <a style="color:#1976d2" class="primary-button" onclick="showMoreExtra('dots6', 'more6', 'myBtn6')" id="myBtn6" #myBtn>read more</a>
                  <br>

                </p>
                <br><br>
                <p>

                </p>
              </div>

            </div> <!-- /timeline-block -->

            <div class="timeline-block">

              <div class="timeline-ico">
                <i class="fa fa-graduation-cap"></i>
              </div>

              <div class="timeline-header">
                <h3>Level 5 Certificate</h3>
                <p>September 2017 - April 2018</p>
              </div>

              <div class="timeline-content">
                <h3 class="remove-margin">Ballyfermot College of Further Education</h3><h4></h4>
                <p class="lead remove-margin" style="font-size: initial;">Technology used: HTML | 3Ds Max | Unity | C#</p>
                <p class="lead animated pulse infinite">
                  My experience at Ballyfermot College of Further Education, where I pursued a Level 5 Certificate in Computer Games and Interactive Entertainment Development, was nothing short of rewarding.<span style="color: black" id="dots7" #dots>...</span>
                  <span id="more7" #more>
                    Earning a Merit grade reflects my dedication and enthusiasm for this field.
                    <br /><br />
                    During my time at the college, I had the opportunity to delve into key modules such as 3D Asset Design, Game Design, and Games Analysis Design. These modules not only expanded my technical knowledge but also nurtured my creativity and problem-solving skills.
                    <br /><br />
                    One significant highlight of my experience was my involvement in the project "Eerie Escape," where I served as a 3D modeler and texturer. This hands-on experience allowed me to apply what I had learned in class to a real-world project, working collaboratively with a team to bring our creative vision to life.
                    <br /><br />
                    My time at Ballyfermot College was instrumental in laying the foundation for my passion for game design. It provided me with the skills, knowledge, and confidence necessary to pursue my passion in this dynamic and ever-evolving industry.
                  </span>
                  <br>
                  <a style="color:#1976d2" class="primary-button" onclick="showMoreExtra('dots7', 'more7', 'myBtn7')" id="myBtn7" #myBtn>read more</a>
                  <br>

                </p>
                <br><br>
                <p>

                </p>
              </div>

            </div> <!-- /timeline-block -->

          </div> <!-- /timeline-wrap -->

        </div> <!-- /col-twelve -->
        
    </div> <!-- /resume-timeline -->
    
</section> <!-- /features -->


<!-- Portfolio Section
================================================== -->
<section id="portfolio">

    <div class="row section-intro">
        <div class="col-twelve">

            <h5>Portfolio</h5>
            <h1>Links</h1>

            <p class="lead">Here's a collection of relevant links of the work I have done during my studies.</p>
             		
        </div>
    </div>

    <div class="row resume-timeline">

        <div class="col-twelve">

            <div class="timeline-wrap">

                <div class="timeline-block">

                    <div class="timeline-ico">
                        <i class="fa fa-github"></i>
                    </div>

                    <div class="timeline-header">
                        <h3></h3>
                    </div>

                    <div class="timeline-content">
                        <h3>Artstation<a href="https://www.artstation.com/nicolehey" style="color:#0dcaf0">[LINK]</a></h3><h4></h4>
                        <p class="lead">On my Artstation profile you'll find most of my personal work. If you have any questions about it please contact me!</p>
                    </div>

                </div> <!-- /timeline-block -->

                <div class="timeline-block">

                    <div class="timeline-ico">
                        <i class="fa fa-github"></i>
                    </div>

                    <div class="timeline-header">
                        <h3></h3>
                    </div>

                    <div class="timeline-content">
                        <h3>Sketchfab<a href="https://sketchfab.com/NicoleOBrien/models" style="color:#0dcaf0">[LINK]</a></h3><h4></h4>
                        <p class="lead">More of my personal work on Sketchfab.</p>
                    </div>

                </div> <!-- /timeline-block -->

                <div class="timeline-block">

                    <div class="timeline-ico">
                        <i class="fa fa-link"></i>
                    </div>

                    <div class="timeline-header">
                        <h3>June 2020</h3>
                    </div>

                    <div class="timeline-content">
                        <h3>Assault the Tower<a href="https://bifuriouss.itch.io/assault-the-tower" style="color:#0dcaf0">[LINK]</a></h3><h4></h4>
                        <p class="lead">My 4th year project I created in Unreal Engine.</p>
                    </div>

                </div> <!-- /timeline-block -->
                <div class="timeline-block">

                    <div class="timeline-ico">
                        <i class="fa fa-github"></i>
                    </div>

                    <div class="timeline-header">
                        <h3>January 2023</h3>
                    </div>

                    <div class="timeline-content">
                        <h3>Dissertation<a href="https://docs.google.com/document/d/1IpGqOkGBDXcbJw1NHnWvg3RrYVCgP6h7/edit?usp=sharing&ouid=115436486352878839217&rtpof=true&sd=true" style="color:#0dcaf0">[LINK]</a></h3><h4></h4>
                        <p class="lead">A link to my 4th year dissertation.</p>
                    </div>

                </div> <!-- /timeline-block -->

                <div class="timeline-block">

                    <div class="timeline-ico">
                        <i class="fa fa-link"></i>
                    </div>

                    <div class="timeline-header">
                        <h3>April 2018</h3>
                    </div>

                    <div class="timeline-content">
                        <h3>Eerie Escape<a href="https://www.newgrounds.com/portal/view/711192" style="color:#0dcaf0">[LINK]</a></h3><h4></h4>
                        <p class="lead">The link to my first year work experience project we made in Unity.</p>
                    </div>

                </div> <!-- /timeline-block -->

            </div> <!-- /timeline-wrap -->   			

        </div> <!-- /col-twelve -->
        
    </div> <!-- /resume-timeline -->
    
</section> <!-- /portfolio --> 	

<!-- contact
================================================== -->
<section id="contact">

    <div class="row section-intro">
        <div class="col-twelve">

            <h5>Contact</h5>
            <h1>I'd Love To Hear From You.</h1>

            <p class="lead">If you have any questions or are interested in any of the work I do, please do get in contact with me. I appreciate the read and thanks!</p>

        </div> 
    </div> <!-- /section-intro -->

    <div class="row contact-info">

        <div class="col-four tab-full">

            <div class="icon">
                <i class="icon-pin"></i>
            </div>

            <h5>Glad you made it this far!</h5>

            <p class="lead">
            Your friendly<br>
            Neighborhood<br>
            Game Designer
            </p>

        </div>

        <div class="col-four tab-full">

            <div class="icon">
                <i class="icon-mail"></i>
            </div>

            <h5>Email Me here</h5>

            <p class="lead">nicoleobrien304@hotmail.co.uk<br>	     
            </p>

        </div>

        <div class="col-four tab-full">

            <div class="icon">
                <i class="icon-phone"></i>
            </div>

            <h5>Call Me Here</h5>

            <p class="lead">
                Mobile: (+353) 83 802 1111<br>
            </p>

        </div>
        
    </div> <!-- /contact-info -->
    
</section> <!-- /contact -->


<!-- footer
================================================== -->

<footer style="background-color: #151515;">

    <div class="row section-intro">
        <div class="col-twelve">

            <ul class="footer footer-social">        
                <li><a href="https://www.facebook.com/"><i class="fa fa-facebook"></i></a></li>
                <li><a href="https://www.linkedin.com/in/nicole-o-brien-267162175/"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="https://www.instagram.com/"><i class="fa fa-instagram"></i></a></li>
                <li><a href="https://docs.google.com/document/d/1IpGqOkGBDXcbJw1NHnWvg3RrYVCgP6h7/edit?usp=sharing&ouid=115436486352878839217&rtpof=true&sd=true"><i class="fa fa-bookmark"></i></a></li>
            </ul> 
            
        </div>
    </div>
    	
</footer>  
